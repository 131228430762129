<template>
  <div>
    <Navbar class="nav-z-idx"/>
    <div class="container-v3">
      <div class="center-content-v3">
        <div class="form-verify-v3">
          <span class="title-access-desktop mb-3">Sign in as <br>{{emailUser ? emailUser : 'user@domain.com'}}.</span>
          <button class="btn-access-desktop my-3" @click="getDesktopToken()"><span class="text-btn-access-desktop">Open Desktop App</span></button>
          <span class="text-access-desktop mt-3">Want to sign in with a different account? <span @click="logOut()" class="text-blue cursor-pointer">Sign out</span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../../../components/nav-login-registerV3.vue";

export default {
  components: {
    Navbar
  },
  computed: {
    emailUser() {
      return this.$store.getters['user/email']
    }
  },
  beforeMount() {
    if(localStorage.getItem('user') == null || localStorage.getItem('user') == undefined){
        this.$router.push({name: 'login', query:{...this.$route.query}})
    }
  },
  methods: {
    logOut(){
      this.$store.dispatch('auth/logOut')
      .then(()=> {
        this.$router.push({name: 'login', query:{...this.$route.query}})
      })
    },
    getDesktopToken(){
      this.$store.dispatch('user/getTokenDesktop', {data: this.$route.query.hash})
    }
  }
}
</script>

<style>
.title-access-desktop{
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.04em;
}
.btn-access-desktop{
    width: 100%;
    padding: 16px 0;
    background: #00AAFF;
    border: none;
    border-radius: 4px;
}
.text-btn-access-desktop{
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
}
.text-access-desktop{
    color: #919394;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.02em;
}
</style>